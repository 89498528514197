import React, { useState, useEffect } from 'react';

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Field, Label, Switch, } from '@headlessui/react'
import { HeartIcon } from '@heroicons/react/24/solid'
import { ArrowPathIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import moment from 'moment';

interface Date {
    id: number
    place: string
    isDone: number
    comment: string
    createdBy: string
    date: string

}

export default function Calendar() {

    const [isCreatedModalOpen, setIsCreatedModalOpen] = useState(false)
    const [isUpdatedModalOpen, setIsUpdatedModalOpen] = useState(false)
    const [dateToAdd, setDateToAdd] = useState({
        place: "",
        createdBy: "Maëva",
        isDone: 0
    })

    const [dateToUpdate, setDateToUpdate] = useState<Date | null>(null)

    const [dates, setDates] = useState<Date[]>([])
    const [isSending, setIsSending] = useState<boolean>(false)
    const [isFetching, setIsFetching] = useState<boolean>(true)
    const [selectedDate, setSelectedDate] = useState<Date | null>(null)
    useEffect(() => {
        fetchDates()
    }, []);

    useEffect(() => {
        if (!isCreatedModalOpen) {
            setDateToAdd({
                place: "",
                createdBy: "Maëva",
                isDone: 0
            })
        }
    }, [isCreatedModalOpen]);

    useEffect(() => {
        if (!isUpdatedModalOpen) {
            setDateToUpdate(null)
        }
    }, [isUpdatedModalOpen]);


    const addDate = (e: any) => {
        e.preventDefault()
        setIsSending(true)
        fetch(`${process.env.REACT_APP_API_URL}/calendarDate/add`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dateToAdd)
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Erreur")
                }
                return response.json()
            })
            .then((json) => {
                setIsCreatedModalOpen(false)
                fetchDates()
                setDateToAdd({
                    place: "",
                    createdBy: "",
                    isDone: 0
                })
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setIsSending(false)
            })
    }

    const updateDate = (e: any) => {
        e.preventDefault()
        setIsSending(true)
        fetch(`${process.env.REACT_APP_API_URL}/calendarDate/update/${dateToUpdate!.id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...dateToUpdate, isDone: 1 })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Erreur")
                }
                return response.json()
            })
            .then((json) => {
                setIsUpdatedModalOpen(false)
                fetchDates()
                setDateToAdd({
                    place: "",
                    createdBy: "",
                    isDone: 0
                })
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setIsSending(false)
            })
    }

    const fetchDates = () => {
        setIsFetching(true)
        fetch(`${process.env.REACT_APP_API_URL}/calendarDate/list`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            if (!response.ok) {
                throw new Error("Erreur")
            }
            return response.json()
        })
            .then((json) => {
                console.log(json)
                setDates(json)
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setIsFetching(false)
            })
    }

    const [onglets, setOnglets] = useState(['Tout', 'A faire', 'Fait'])
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)

    const [currentOnglet, setCurrentOnglet] = useState("Tout")

    const deleteDate = () => {
        fetch(`${process.env.REACT_APP_API_URL}/calendarDate/delete`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(selectedDate)
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Erreur")
                }
                return response.json()
            })
            .then((json) => {
                setSelectedDate(null)
                setIsOpenModalDelete(false)
                fetchDates()
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setIsSending(false)
            })
    }

    return (
        <div className="p-3">
            <div className='sticky top-0 bg-background'>
                <div className="flex mt-4 justify-between items-center px-2">
                    <span className="font-semibold text-white text-lg">Mon calendrier des dates</span>
                    <div className='bg-secondary p-1.5 rounded-md'>
                    <ArrowPathIcon onClick={fetchDates} className='text-white h-4 w-4' />
                    </div>
                </div>

                <div className='flex flex-row items-center my-4 space-x-2'>
                    <nav aria-label="Tabs" className="flex space-x-6 w-full bg-secondary rounded-lg p-2">
                        {onglets.map((onglet) => (
                            <p
                                key={onglet}
                                onClick={() => {
                                    setCurrentOnglet(onglet)
                                }}
                                className={`${currentOnglet === onglet ? "bg-selected" : ""} p-2 rounded-md text-sm font-medium w-full text-center text-white`}
                            >
                                {onglet}
                            </p>
                        ))}
                    </nav>


                    <div className="flex justify-center items-center">
                        <button
                            type="button"
                            onClick={() => setIsCreatedModalOpen(true)}
                            className="cursor-pointer rounded-md bg-action px-4 py-3 text-sm font-semibold text-white"
                        >
                            +
                        </button>
                    </div>
                </div>
            </div>
            {isFetching ?
                <div className='flex justify-center items-center h-[50vh]'>
                    <ArrowPathIcon onClick={fetchDates} className='text-white h-20 w-20 animate-spin-slow' />
                </div>
                :
                <div className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4 overflow-y-scroll">
                    {dates.filter((d) => {
                        if (currentOnglet === "A faire") {
                            return d.isDone === 0
                        } else if (currentOnglet === "Fait") {
                            return d.isDone === 1
                        } else {
                            return true
                        }
                    }).map((date: Date) => (
                        <div key={date.place} className="col-span-1 flex rounded-md">
                            <div className='flex w-full' onClick={() => setSelectedDate(date)}>
                                <div className={`${date.createdBy === "Maëva" ? "bg-maeva" : "bg-valentin"} flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white`}>
                                    {date.place.charAt(0)}
                                </div>
                                <div className="flex w-full items-center justify-between bg-secondary">
                                    <div className="flex-col flex truncate px-4 py-2 text-sm">
                                        <span className="font-medium text-white">
                                            {date.place}
                                        </span>
                                        {date.isDone ?
                                            <span className="text-white text-xs mt-0.5">{moment(date.date).format("DD/MM/YYYY")}</span>
                                            :
                                            <span className="text-white text-xs mt-0.5">A faire</span>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div onClick={() => {
                                setDateToUpdate(date)
                                setIsUpdatedModalOpen(true)
                            }} className={'bg-secondary flex w-16 flex-shrink-0 items-center justify-center rounded-r-md text-sm font-medium '}>
                                {date.isDone ?
                                    <CheckCircleIcon className='text-valid h-7 w-7 cursor-pointer' />
                                    :
                                    <CheckCircleIcon className='text-gray-500 h-7 w-7 cursor-pointer' />
                                }
                            </div>
                        </div>
                    ))}
                </div>
            }
            <Dialog open={isCreatedModalOpen} onClose={() => { }} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-background bg-opacity-70 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
                <form id="AddDate" onSubmit={addDate}>
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="w-full relative transform overflow-hidden rounded-lg bg-secondary px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >

                                <div className="text-center">
                                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-white w-full">
                                        Ajouter un date
                                    </DialogTitle>
                                </div>

                                <div className="mt-3 text-center space-y-3">
                                    <input
                                        id="datePlace"
                                        name="datePlace"
                                        type="text"
                                        required
                                        value={dateToAdd.place}
                                        onChange={(e) => setDateToAdd({ ...dateToAdd, place: e.currentTarget.value })}
                                        placeholder='Cinéma, Disneyland, Zoo ...'
                                        className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm bg-selected placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                    />
                                    <Field className="flex items-center justify-center">
                                        <Label as="span" className="mr-3 text-sm">
                                            <span className="font-medium text-white">Maëva</span>{' '}
                                        </Label>
                                        <Switch
                                            checked={dateToAdd.createdBy === "Valentin"}
                                            onChange={() => setDateToAdd({ ...dateToAdd, createdBy: dateToAdd.createdBy === "Valentin" ? "maëva" : "Valentin" })}
                                            className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-maeva transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-valentin"
                                        >
                                            <span className="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5">
                                                <span
                                                    aria-hidden="true"
                                                    className="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                >
                                                    <HeartIcon className='text-maeva h-3.5 w-3.5' />
                                                </span>
                                                <span
                                                    aria-hidden="true"
                                                    className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                >
                                                    <HeartIcon className='text-valentin h-3.5 w-3.5' />
                                                </span>
                                            </span>
                                        </Switch>
                                        <Label as="span" className="ml-3 text-sm">
                                            <span className="font-medium text-white">Valentin </span>{' '}
                                        </Label>
                                    </Field>
                                </div>

                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="submit"
                                        disabled={isSending}
                                        className="disabled:opacity-75 inline-flex w-full justify-center rounded-md bg-action px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                    >
                                        {isSending ? "Ajout en cours ..." : "Ajouter"}
                                    </button>
                                    <button
                                        type="button"
                                        data-autofocus
                                        disabled={isSending}
                                        onClick={() => setIsCreatedModalOpen(false)}
                                        className="disabled:opacity-75 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm sm:col-start-1 sm:mt-0"
                                    >
                                        Annuler
                                    </button>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </form>
            </Dialog>
            <Dialog open={isUpdatedModalOpen} onClose={() => { }} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-background bg-opacity-70 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
                <form id="updateDate" onSubmit={updateDate}>
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="w-full relative transform overflow-hidden rounded-lg bg-secondary px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >
                                <div className="text-center">
                                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-white w-full">
                                        {dateToUpdate?.place}
                                    </DialogTitle>
                                </div>
                                <div className="mt-3 space-y-3">
                                    <input
                                        id="date"
                                        name="date"
                                        type="date"
                                        required
                                        value={dateToUpdate?.date}
                                        onChange={(e) => setDateToUpdate({ ...dateToUpdate!, date: e.currentTarget.value })}
                                        placeholder='Cinéma, Disneyland, Zoo ...'
                                        className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm bg-selected placeholder:text-gray-400 sm:text-sm sm:leading-6"

                                    />
                                    <div className='flex flex-col'>
                                        <span className='text-white'>Commentaire :</span>
                                        <textarea
                                            id="comment"
                                            name="comment"
                                            rows={3}
                                            className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm bg-selected placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                            value={dateToUpdate?.comment}
                                            onChange={(e) => setDateToUpdate({ ...dateToUpdate!, comment: e.currentTarget.value })}
                                        />
                                    </div>
                                </div>

                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="submit"
                                        disabled={isSending}
                                        className="disabled:opacity-75 inline-flex w-full justify-center rounded-md bg-action px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:col-start-2"
                                    >
                                        {isSending ? "Ajout en cours ..." : "Confirmer"}
                                    </button>
                                    <button
                                        type="button"
                                        data-autofocus
                                        disabled={isSending}
                                        onClick={() => setIsUpdatedModalOpen(false)}
                                        className="disabled:opacity-75 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm sm:col-start-1 sm:mt-0"
                                    >
                                        Annuler
                                    </button>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </form>
            </Dialog>
            <Dialog open={!!selectedDate} onClose={() => setSelectedDate(null)} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-background bg-opacity-70 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="w-full relative transform overflow-hidden rounded-lg bg-secondary px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            {isOpenModalDelete ?
                                <>
                                    <div className="text-center">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-white w-full">
                                            Confirmer la suppression
                                        </DialogTitle>
                                    </div>
                                    <div className="mt-3 space-y-3 flex flex-col">
                                        <span className='text-center text-gray-400'> Voulez-vous confirmer la supression de ce date ?</span>
                                    </div>
                                    <div className="mt-3 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            data-autofocus
                                            disabled={isSending}
                                            onClick={deleteDate}
                                            className="disabled:opacity-75 mt-3 inline-flex w-full justify-center rounded-md bg-red-400 px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:col-start-1 sm:mt-0"
                                        >
                                            Oui
                                        </button>
                                        <button
                                            type="button"
                                            data-autofocus
                                            disabled={isSending}
                                            onClick={() => setIsOpenModalDelete(false)}
                                            className="disabled:opacity-75 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm sm:col-start-1 sm:mt-0"
                                        >
                                            Non
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="text-center">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-white w-full">
                                            {selectedDate?.place}
                                        </DialogTitle>
                                    </div>
                                    <div className="mt-3 space-y-3 flex flex-col">
                                        <span className='text-white '> Ajouté par :
                                            <span className={`${selectedDate?.createdBy === "Valentin" ? "bg-valentin" : "bg-maeva"} ml-2 inline-flex items-center rounded-full px-2 py-1 text-xs font-medium `}>
                                                {selectedDate?.createdBy}
                                            </span>
                                        </span>
                                        {selectedDate?.isDone ?
                                            <>
                                                <span className='text-white'> Réalisé le : {moment(selectedDate?.date).format("DD/MM/YYYY")}</span>
                                                <div className='flex flex-col'>
                                                    <span className='text-white'>Commentaire :</span>
                                                    <textarea
                                                        id="comment"
                                                        name="comment"
                                                        rows={3}
                                                        className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm bg-selected placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                        value={selectedDate?.comment}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <span className='text-white'> A faire</span>
                                        }
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            data-autofocus
                                            disabled={isSending}
                                            onClick={() => setSelectedDate(null)}
                                            className="disabled:opacity-75 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm sm:col-start-1 sm:mt-0"
                                        >
                                            Fermer
                                        </button>
                                        <button
                                            type="button"
                                            data-autofocus
                                            disabled={isSending}
                                            onClick={() => setIsOpenModalDelete(true)}
                                            className="disabled:opacity-75 mt-3 inline-flex w-full justify-center rounded-md bg-red-400 px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:col-start-1 sm:mt-0"
                                        >
                                            Supprimer
                                        </button>
                                    </div>
                                </>
                            }
                        </DialogPanel>
                    </div>
                </div>
            </Dialog >
        </div >
    )

}